import axiosInstance from "../services/axiosIntercept";

const useUpdateLocation = () => {
  const updateLocation = async (locationId, body) => {
    try {
      const updateBody = {
        ...body,
        memberReceiptStampAmount: body.memberReceiptStampAmount
          ? Number(body.memberReceiptStampAmount)
          : 0,
      };

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/business/location/update/${locationId}`,
        updateBody,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  };

  return updateLocation;
};

export default useUpdateLocation;
