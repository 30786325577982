import { isAxiosError } from "axios";
import axiosInstance from "./axiosIntercept";

const auth = {
  username: "",
  password: "bac26a445c4c8360626eed1e8db5f138-us11", // Mailchimp API key
};
const serverPrefix = "us11";
const baseURL = `https://${serverPrefix}.api.mailchimp.com/3.0`;

type EmailTemplate = {
  id: number;
  type: string;
  name: string;
};

// https://mailchimp.com/developer/marketing/api/templates/list-templates/
export async function getEmailTemplates(): Promise<EmailTemplate[]> {
  try {
    const response = await axiosInstance.get<EmailTemplate[]>(`/templates`, {
      params: {
        fields: "id,type,name",
      },
      baseURL,
      auth,
    });
    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
      throw new Error(`API Error: ${error.response?.status}`);
    } else {
      console.error("Network Error:", error.message);
      throw new Error(`Network Error: ${error.message}`);
    }
  }
}

// https://mailchimp.com/developer/marketing/api/templates/get-template-info/
export async function getEmailTemplate(id: number): Promise<EmailTemplate> {
  try {
    const response = await axiosInstance.get<EmailTemplate>(
      `/templates/${id}`,
      {
        params: {
          fields: "id,type,name,html",
        },
        baseURL,
        auth,
      },
    );
    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
      throw new Error(`API Error: ${error.response?.status}`);
    } else {
      console.error("Network Error:", error.message);
      throw new Error(`Network Error: ${error.message}`);
    }
  }
}

// https://mailchimp.com/developer/marketing/api/templates/update-template/
export async function updateTemplate(id: number): Promise<void> {}
