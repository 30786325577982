import React, { useMemo } from "react";
import "./deal.css";
import BrandLogoPlaceholder from "../../assets/placeholders/meed logo-cream on grey.svg";
import Location from "../Location/location";
import Timer from "../Timer/timer";
import Collapse from "../Collapse/collapse";
import Progress from "../Progress/progress";
import { ReactComponent as CalendarIcon } from "../../assets/symbols/calendar.svg";
import { DateTime } from "luxon";
import CampaignPicturePlaceholder from "../CampaignPicturePlaceholder";

/**
 * @param {object} props
 * @param {string|undefined} props.termsNConditions
 * @param {Date|undefined} props.startDate
 * @param {Date|undefined} props.endDate
 * @param {string|undefined} props.programName
 * @param {string|undefined} props.eventCode
 * @returns {string}
 */
function TermsAndConditions({
  termsNConditions,
  startDate,
  endDate,
  programName,
  eventCode,
}) {
  // Format dates
  const startDateFormatted = startDate
    ? DateTime.fromJSDate(startDate).toISODate()
    : "N/A";
  const endDateFormatted = endDate
    ? DateTime.fromJSDate(endDate).toISODate()
    : "N/A";

  // Replace placeholders in termsNConditions
  let termsNConditionsUpdated = termsNConditions || "";
  termsNConditionsUpdated = termsNConditionsUpdated.replace(
    /\[START_DATE\]/g,
    startDateFormatted,
  );
  termsNConditionsUpdated = termsNConditionsUpdated.replace(
    /\[END_DATE\]/g,
    endDateFormatted,
  );
  termsNConditionsUpdated = termsNConditionsUpdated.replace(
    /\[Business\]/g,
    programName,
  ); // Replace [Business] with the program name

  // Replace [Event] only if eventCode is not null and not empty
  if (eventCode) {
    termsNConditionsUpdated = termsNConditionsUpdated.replace(
      /\[Event\]/g,
      eventCode,
    );
  } else {
    termsNConditionsUpdated = termsNConditionsUpdated.replace(
      /\[Event\]/g,
      "event",
    );
  }

  return (
    <div className="wysiwyg">
      {termsNConditionsUpdated.split("\n").map((item) => (
        <p key={item}>{item}</p>
      ))}
    </div>
  );
}

/**
 * @param {object} props
 * @param {string[] | undefined} [props.requirements] array of voucher requirements
 * @param {string | undefined} [props.description] voucher description
 * @param {string | undefined} [props.termsNConditions] voucher description
 * @param {Date | undefined} [props.startDate] voucher start date
 * @param {Date | undefined} [props.endDate] voucher end date
 * @param {boolean | undefined} [props.fullView] (optional)
 * @param {boolean | undefined} [props.hasProgressCard] (optional)
 * @param {string|null} props.background
 * @param {string} [props.brandLogo]
 * @param {string} [props.campaignPicture]
 * @param {string|null} props.brandName
 * @param {string|JSX.Element} props.offerTitle
 * @param {string|JSX.Element} props.offerValue
 * @param {string|null} props.location
 * @param {string} props.duration
 * @param {string} [props.visits]
 * @returns {JSX.Element} component
 */
function Deal({
  fullView,
  hasProgressCard,
  background,
  brandLogo,
  campaignPicture,
  brandName,
  offerTitle,
  offerValue,
  location,
  duration,
  startDate,
  endDate,
  requirements,
  description,
  termsNConditions,
  visits,
}) {
  /**
   * @type {string}
   */
  const expirationDateLabelShort = useMemo(() => {
    const campaignHasStarted =
      DateTime.fromJSDate(startDate).startOf("day") <=
      DateTime.now().startOf("day");
    if (!campaignHasStarted) {
      return "Not yet started";
    }

    const remainingDays = DateTime.fromJSDate(endDate).diffNow("days").days;

    if (remainingDays < 0) {
      return "Expired";
    }

    return `${Math.floor(remainingDays)} day${
      remainingDays > 1 ? "s" : ""
    } left`;
  }, [startDate, endDate]);

  /**
   * @type {string}
   */
  const expirationDateLabelLong = useMemo(() => {
    return endDate
      ? `This offer expires on ${DateTime.fromJSDate(endDate).toISODate()}`
      : "";
  }, [endDate]);

  return (
    <div className="deal">
      {/* There is two layout for this component:
          1) "Default View" which looks like a single compressed card with
             all data including location and duration. This can be used
             on a variety of pages where we need a voucher preview.
          2) "Full View" which looks like 3 cards where first of them repeats
             Default view, but location and duration taken away and placed
             as more detailed 2nd and 3rd card.
         In terms or code it means that we output first card in any case
         (location and duration inside are optional), and then 2nd and 3rd
         card only in a full view.
       */}
      <div className="deal__card">
        <div
          className="deal__stripe"
          style={{ backgroundColor: background }}
        ></div>
        <div className="deal__head">
          <div className="deal__venue">{brandName}</div>
          {!fullView ||
            (Number(duration) && (
              <div className="deal__countdown">
                <Timer>
                  {duration} day{duration != 1 && "s"}
                </Timer>
              </div>
            ))}
        </div>
        <div className="deal__body">
          <div className="deal__preview">
            {campaignPicture ? (
              <img className="deal__illustration" src={campaignPicture} />
            ) : (
              <CampaignPicturePlaceholder />
            )}
            {/* Add advanced options on .deal__leaf: style="padding: 3px; background-color: #F70000;" */}
            <div className="deal__leaf">
              {/* If user uploaded portrait oriented image add .deal__logo--portrait modifier on .deal__logo */}
              <img
                className="deal__logo"
                src={brandLogo ? brandLogo : BrandLogoPlaceholder}
              />
            </div>
          </div>
          <div className="deal__content">
            <div className="deal__offer-heading clamping clamping--2-rows">
              {offerTitle ? offerTitle : ""}
            </div>
            <div className="deal__offer-value clamping clamping--3-rows">
              {offerValue ? offerValue : ""}
            </div>
            {/* {!fullView && (
              <div className="deal__location">
                <Location>{location}</Location>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {fullView && (
        <>
          {hasProgressCard && (
            <div className="deal__card">
              <div className="deal__progress-inner">
                <div className="deal__textual-progress">
                  <div className="deal__unlocking-heading">
                    Unlocked
                    <br /> Progress
                  </div>
                  <div className="deal__stats">
                    <div className="deal__unlocked-total">
                      0 / {visits ? visits : "0"}
                    </div>
                    <div className="deal__numbers">Number of stamps</div>
                  </div>
                </div>
                <div className="deal__visual-progress">
                  <Progress value={0} color={background} />
                </div>
              </div>
            </div>
          )}
          <div className="deal__card">
            <div
              className="deal__stripe"
              style={{ backgroundColor: background }}
            ></div>
            <div className="deal__expiration">
              <CalendarIcon className="deal__calendar" />
              <div className="deal__expiration-details">
                <div className="deal__days-left">
                  {expirationDateLabelShort}
                </div>
                <div className="deal__expiration-date">
                  {expirationDateLabelLong}
                </div>
              </div>
            </div>
            <div className="deal__full-address">
              <Location variant={"large"}>{location}</Location>
            </div>
          </div>
          <div className="deal__card">
            <div
              className="deal__stripe"
              style={{ backgroundColor: background }}
            ></div>
            <div className="deal__article">
              <Collapse textOnHandler={"About this Voucher"}>
                <div className="wysiwyg">
                  <p>{description}</p>
                  <h3>Requirements:</h3>
                  <ol>
                    {requirements?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ol>
                </div>
              </Collapse>
              <Collapse textOnHandler={"Terms and Conditions"}>
                <TermsAndConditions
                  termsNConditions={termsNConditions}
                  startDate={startDate}
                  endDate={endDate}
                  programName={brandName}
                  eventCode=""
                />
              </Collapse>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Deal;
