import "./shared/shared.css";
import LandingPage from "./Pages/Landing/landing_page";
//import PdfPage from "./Pages/PdfPage/pdfPage";
import PdfPageWrapper from "./Pages/PdfPage/pdfPageWrapper";
import MainPage from "./Pages/Mainpage/main_page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as selectors from "./selectors/selectors";
import Shop from "./Components/Shop/shop";
import SocialPoster from "./Components/SocialPoster/socialPoster";
import SocialVoucherPoster from "./Components/SocialPoster-voucher/socialPoster-voucher";
import Email_Verification_Page from "./Pages/EmailVerification/email_verification";
import LoginImpersonatePage from "./Pages/LoginImpersonate/login_impersonate_page";
import Looker from "./Pages/Looker/looker";
import Approval from "./Pages/PaymentApproval/approval";
import "./App.css";
import OnBoardingForm from "./Pages/OnBoardingForm/OnBoardingForm";
import Mailchimp from "./Pages/Mailchimp/mailchimp";

function App() {
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/fandbhongkong" element={<OnBoardingForm />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/pdf" element={<PdfPageWrapper />} />
        <Route path="/dashboard/:page?/:id?" element={<MainPage />} />
        <Route path="/impersonate" element={<LoginImpersonatePage />} />
        <Route path="/social" element={<SocialPoster />} />
        <Route path="/social-voucher" element={<SocialVoucherPoster />} />
        <Route path="/verify/:token?" element={<Email_Verification_Page />} />
        <Route path="/payment" element={<Approval />} />
        <Route path="/mailchimp" element={<Mailchimp />} />
        {/* <Route path="/looker" element={<Looker />} /> */}
        {/* <Route path="/cube" element={<YourComponent />} /> */}
        <Route
          path="*"
          element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/"} replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
