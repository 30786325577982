import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./mailchimp.module.css";
import useSWR from "swr";
import { getEmailTemplates } from "../../services/emailTemplatesService";
import mjml from "mjml-browser";

const Mailchimp: React.FC = () => {
  const { data } = useSWR("email-templates", () => {
    return getEmailTemplates();
  });

  const [cuztomizations, setCustomziations] = useState({
    bgColor: "#fff",
    textColor: "#000",
    logo: "https://placehold.co/200x100",
    headerText: "Header text",
    contentText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    footerText: "Footer text",
  });

  const [html, setHtml] = useState("");

  const compileHtml = useCallback(() => {
    setHtml(
      mjml(`
      <mjml>
        <mj-body>
          <mj-section background-color="${cuztomizations.bgColor}">
            <mj-column>
              <mj-image width="200px" src="${cuztomizations.logo}"></mj-image>
              <mj-divider border-color="${cuztomizations.textColor}"></mj-divider>
              <mj-text font-size="20px" color="${cuztomizations.textColor}" font-family="helvetica">${cuztomizations.headerText}</mj-text>
              <mj-text font-size="20px" color="${cuztomizations.textColor}" font-family="helvetica">${cuztomizations.contentText}</mj-text>
              <mj-text font-size="20px" color="${cuztomizations.textColor}" font-family="helvetica">${cuztomizations.footerText}</mj-text>
            </mj-column>
          </mj-section>
        </mj-body>
      </mjml>
      `).html,
    );
  }, [cuztomizations]);

  return (
    <div>
      <div>
        <h1>Email templates ({data?.length ?? 0})</h1>
        {data?.map((template) => (
          <>
            {template.id} - {template.name}
          </>
        ))}
      </div>
      <div>
        <h1>Customization</h1>
        <div>
          <input
            type="text"
            value={cuztomizations.bgColor}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                bgColor: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <input
            type="text"
            value={cuztomizations.logo}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                logo: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <input
            type="text"
            value={cuztomizations.textColor}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                textColor: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <input
            type="text"
            value={cuztomizations.headerText}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                headerText: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <input
            type="text"
            value={cuztomizations.contentText}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                contentText: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <input
            type="text"
            value={cuztomizations.footerText}
            onChange={(e) =>
              setCustomziations((state) => ({
                ...state,
                footerText: e.target.value,
              }))
            }
          />
        </div>
        <button onClick={compileHtml}>Update</button>
      </div>
      <div>
        <h1>Email template html</h1>
        <textarea
          readOnly
          value={html}
          rows={10}
          style={{ width: "100%" }}
        ></textarea>
      </div>
      <div>
        <h1>Email template preview</h1>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
};

export default Mailchimp;
