import React from "react";
import "./powered_by_meed.css";

function PoweredByMeed({ data }) {
  console.log(data);
  return (
    <div
      className={`powered-by-meed ${data === 2 ? "template-2" : ""} ${
        data === 3 ? "template-3" : ""
      }`}
    >
      Powered by Meed
    </div>
  );
}

export default PoweredByMeed;
