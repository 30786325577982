import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import "./socialPoster.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";
import { toPng, toJpeg } from "html-to-image";
import { useRef, useCallback } from "react";
import { Button } from "antd";
import { getQrCodeUrlForProgramUuid } from "../../utils/utils";
import useGetLocations from "../../Hooks/getLocation";

/**
 * @param {object} data
 * @param {string} data.businessName
 * @param {string} data.businessLogo
 * @param {string} data.programUuid
 * @param {string} data.industry
 * @param {string} data.address
 * @param {string} data.brandColor
 * @param {string} data.size
 * @param {string} data.heroImageId
 */
const openScoialPage = (data) => {
  const queryParams = new URLSearchParams(data).toString();

  const url = `/pdf?${queryParams}`;
  window.open(url, "_blank");
};

const QR_CODE_PLACEHOLDER =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IndoaXRlIi8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTIwIDIwaDYwdjYwSDIwek0zNSAzNWgzMHYzMEgzNXoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMTIwIDIwaDYwdjYwaC02MHpNMTM1IDM1aDMwdjMwaC0zMHoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMjAgMTIwaDYwdjYwSDIwek0zNSAxMzVoMzB2MzBIMzV6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDkwaDIwdjIwSDkwek0xMjAgOTBoMjB2MjBoLTIwek0xNTAgOTBoMjB2MjBoLTIwek05MCAxMjBoMjB2MjBIOTB6TTEyMCAxMjBoMjB2MjBoLTIwek0xNTAgMTIwaDIwdjIwaC0yMHpNOTAgMTUwaDIwdjIwSDkwek0xMjAgMTUwaDIwdjIwaC0yMHpNMTUwIDE1MGgyMHYyMGgtMjB6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDIwaDIwdjIwSDkwek05MCA1MGgyMHYyMEg5MHpNMjAgOTBoMjB2MjBIMjB6TTUwIDkwaDIwdjIwSDUweiIvPgo8L3N2Zz4=";

function SocialPoster() {
  const programUuid = useSelector(selectors.getSelectedLpUuid) ?? "";
  const getLocations = useGetLocations(programUuid);
  const [locations, setLocations] = useState([]);
  console.log("programUuid", programUuid);
  console.log("locations", locations?.[0]?.id);
  const location = useLocation();
  console.log("location", location);
  const queryParams = new URLSearchParams(location.search);
  const ref = useRef(null);
  const [currentTemplate, setCurrentTemplate] = useState(0);
  const templates = [0, 0, 0, 0]; // Add more template indexes as they become available

  const handlePrevTemplate = () => {
    setCurrentTemplate((prev) => (prev > 0 ? prev - 1 : templates.length - 1));
  };

  const handleNextTemplate = () => {
    setCurrentTemplate((prev) => (prev < templates.length - 1 ? prev + 1 : 0));
  };

  const downloadImage = async (type) => {
    try {
      // Add background color and improve quality
      const options = {
        quality: 1.0,
        style: {
          margin: "0",
          padding: "0",
        },
      };

      if (type === "png") {
        const dataUrl = await toPng(ref.current, options);
        download(dataUrl, "meed-social-poster.png");
      } else if (type === "jpeg") {
        const dataUrl = await toJpeg(ref.current, options);
        download(dataUrl, "meed-social-poster.jpeg");
      }
    } catch (error) {
      console.error("Error generating image:", error);
    }
  };

  const download = (dataUrl, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataUrl;
    link.click();
  };

  const onBack = useCallback(() => {
    window.close();
  }, []);
  const data = {
    businessName: queryParams.get("businessName"),
    businessLogo: queryParams.get("businessLogo"),
    programUuid: queryParams.get("programUuid"),
    address: queryParams.get("address"),
    brandColor: queryParams.get("brandColor"),
    industry: queryParams.get("industry"),
    locations: queryParams.get("locations"),
    offer:
      "Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer ",
    heroImageId: queryParams.get("heroBannerLogo"),
  };

  const [qrCodeUrl, setQrCodeUrl] = useState(QR_CODE_PLACEHOLDER);

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await getLocations();
      setLocations(response);
      console.log("locations -----------", response);
    };
    fetchLocations();
  }, [getLocations]);

  useEffect(() => {
    // Fetch QR code
    const fetchQrCodeUrl = async () => {
      try {
        const url = await getQrCodeUrlForProgramUuid(
          programUuid,
          String(locations?.[0]?.id),
        );
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Failed to fetch QR code:", error);
        setQrCodeUrl(QR_CODE_PLACEHOLDER);
      }
    };

    fetchQrCodeUrl();
  }, [data.programUuid, data.locations]);

  return (
    <>
      <div className="social-poster">
        <a
          className="social-poster-close-button"
          onClick={onBack}
          style={{ cursor: "pointer" }}
        >
          ← Close
        </a>

        <div className="navigation-arrows">
          <div className={`nav-arrow left`} onClick={handlePrevTemplate}>
            {"<"}
          </div>
          <div className={`nav-arrow right`} onClick={handleNextTemplate}>
            {">"}
          </div>
        </div>

        <div
          className={`social-poster-container ${
            currentTemplate === 2 ? "template-2" : ""
          } ${currentTemplate === 3 ? "template-3" : ""}`}
          ref={ref}
        >
          <div className="top-left-logo">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
              alt="Coffee Place Logo"
              className="logo"
            />
          </div>
          <div
            className={`image-mask ${
              currentTemplate === 1 ? "template-1" : ""
            }${currentTemplate === 3 ? "template-3" : ""}`}
          >
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.heroImageId}.jpg`}
              alt="Hero Image"
              className="hero-image"
            />
          </div>
          <div className="preview_qr">
            <img
              className={`qr_image ${
                currentTemplate === 1 ? "template-1" : ""
              }${currentTemplate === 3 ? "template-3" : ""}`}
              src={qrCodeUrl}
              alt=""
            />
          </div>
          <div className="text-section">
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "30px",
                color:
                  currentTemplate === 2 || currentTemplate === 3
                    ? "#ffeed7"
                    : "#e06932",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Scan for
            </p>
            <h1
              className={`place-name ${
                currentTemplate === 1 ? "template-1" : ""
              } ${currentTemplate === 2 ? "template-2" : ""} ${
                currentTemplate === 3 ? "template-3" : ""
              }`}
            >
              REWARDS
            </h1>
            {/* <span
              style={{ fontWeight: "bold", fontSize: "15px", color: "#e06932" }}
            >
              T
            </span>
            <img
              src="https://img.icons8.com/?size=20&id=eLH7M5ceDQIl&format=png&color=e06932"
              alt="&"
              style={{ width: "12px", height: "12px" }}
            />
            <span
              style={{ fontWeight: "bold", fontSize: "15px", color: "#e06932" }}
            >
              C Apply
            </span> */}
          </div>
          <PoweredByMeed className="powered-by-meed" data={currentTemplate} />
        </div>

        <div className="template-dots">
          {templates.map((_, index) => (
            <div
              key={index}
              className={`template-dot ${
                index === currentTemplate ? "active" : ""
              }`}
              onClick={() => setCurrentTemplate(index)}
            />
          ))}
        </div>

        <div className="download-buttons">
          <Button
            onClick={() => downloadImage("png")}
            className="download-button"
          >
            Download PNG
          </Button>
          <Button
            onClick={() => downloadImage("jpeg")}
            className="download-button"
          >
            Download JPEG
          </Button>
        </div>
      </div>
    </>
  );
}

export default SocialPoster;
