import React, { useRef, useState } from "react";
import "./login_page.css";
import { DeviceUUIDComponent } from "../../device_id";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import * as actions from "../../actions/actions";
import { Turnstile } from "@marsidev/react-turnstile";
import axiosInstance from "../../services/axiosIntercept";
import Label from "../../Components/Label/label";
import AppInput from "../../Components/AppInput/app_input";
import Popup from "../../Components/Popup/popup";
import CookieNotice from "../../Components/CookieNotice/cookie_notice";
import { useSearchParams } from "react-router-dom";
import { awaitNotEmpty } from "../../utils/utils";

function Login_Page({ handleClick, prevPage }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const turnstileRef = useRef(
    /** @type {import("@marsidev/react-turnstile").TurnstileInstance} */ (null),
  );
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);

  const [email, setEmail] = useState(() => {
    const emailFromParams = searchParams.get("email") || "";

    // If email contains "@" symbol, return it; otherwise, set it to an empty string
    return emailFromParams.includes("@") ? emailFromParams : "";
  });

  const [accountUuid, setAccountUuid] = useState(
    searchParams.get("accountUuid") || "",
  );

  console.log("Params:", { email, accountUuid });

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const login_btn = document.getElementById("login-btn-dis");
    login_btn.disabled = true;
    let deviceUUID = DeviceUUIDComponent();
    console.log(email, deviceUUID); //0fb7c7c9-f22d-46aa-85f0-443871271bb4
    dispatch(actions.setAccountUuidToLogIn(accountUuid));

    // Regular expression for a simple email validation
    const emailRegex = /\S+@\S+\.\S+/;

    // Check if the inputEmail matches the emailRegex
    if (!emailRegex.test(email)) {
      notification.error({
        message: "Invalid Email",
        description: "Please enter a valid email address.",
      });
      login_btn.disabled = false;
      return;
    }

    const turnstileToken = await awaitNotEmpty(() =>
      turnstileRef.current?.getResponse(),
    );

    if (!turnstileToken) {
      notification.error({
        message: "Captcha Validation Failed",
        description: "Please complete the captcha challenge.",
      });
      login_btn.disabled = false;
      return;
    }

    notification.info({
      message: "Sending the OTP",
      description: `Please wait...`,
    });

    await axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}v5/auth/portal/getOTP`,
        {
          email: email,
          deviceID: deviceUUID,
          turnstileToken,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        notification.info({
          message: "You will receive the OTP in a moment",
          description: `OTP has been sent to the email address ${email}`,
        });

        dispatch(actions.setEmail(email));
        handleClick();
        login_btn.disabled = false;
      })
      .catch((err) => {
        console.error(err);
        notification.warning({
          message: (
            <>
              Business portal is in beta and only accessible to those who have
              been granted access. Please join the waitlist to get access here{" "}
              <a href="https://www.meedloyalty.com" target="_blank">
                https://www.meedloyalty.com
              </a>
            </>
          ),
        });
        login_btn.disabled = false;
      });
  };

  return (
    <Popup onCloseClick={prevPage} disableOverlayClick>
      <form className="auth auth--login-screen" onSubmit={handleSubmit}>
        <div className="auth__main-heading">
          Business
          <span className="auth__sub-heading">Sign In</span>
        </div>
        <div className="auth__main-note">Log into your account</div>

        <div className="auth__field">
          <Label>Email address</Label>
          <AppInput
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="auth__optional-turnstile-capcha">
          <div
            className={`turnstile ${
              turnstileRequireInteractive ? "turnstile--interactive" : ""
            }`}
          >
            <Turnstile
              siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
              options={{
                appearance: "interaction-only",
              }}
              onError={() => console.log("captcha validation failed")}
              onBeforeInteractive={() => {
                setTurnstileRequireInteractive(true);
              }}
              ref={turnstileRef}
            />
          </div>
        </div>
        <div className="auth__submit">
          <button
            type="submit"
            id="login-btn-dis"
            className="button button--submit button--full-width"
          >
            Login
          </button>
        </div>
        <div className="auth__terms-and-conditions">
          By signing up for and using the Meed Platform, you agree to our{" "}
          <a
            className="auth__terms-link"
            href="https://www.meedloyalty.com/software-as-a-service-terms-of-service"
            target="_blank"
          >
            Terms of Service.
          </a>
        </div>
      </form>
      {/* <CookieNotice /> */}
    </Popup>
  );
}

export default Login_Page;
